import * as React from 'react';
import { SubMenu as RcSubMenu, useFullPath } from 'rc-menu';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import MenuContext from './MenuContext';
import { isValidElement, cloneElement } from '../_util/reactNode';
function SubMenu(props) {
    var _a;
    const { popupClassName, icon, title } = props;
    const context = React.useContext(MenuContext);
    const { prefixCls, inlineCollapsed, antdMenuTheme } = context;
    const parentPath = useFullPath();
    let titleNode;
    if (!icon) {
        titleNode =
            inlineCollapsed && !parentPath.length && title && typeof title === 'string' ? (<div className={`${prefixCls}-inline-collapsed-noicon`}>{title.charAt(0)}</div>) : (<span className={`${prefixCls}-title-content`}>{title}</span>);
    }
    else {
        // inline-collapsed.md demo 依赖 span 来隐藏文字,有 icon 属性，则内部包裹一个 span
        // ref: https://github.com/ant-design/ant-design/pull/23456
        const titleIsSpan = isValidElement(title) && title.type === 'span';
        titleNode = (<>
        {cloneElement(icon, {
                className: classNames(isValidElement(icon) ? (_a = icon.props) === null || _a === void 0 ? void 0 : _a.className : '', `${prefixCls}-item-icon`),
            })}
        {titleIsSpan ? title : <span className={`${prefixCls}-title-content`}>{title}</span>}
      </>);
    }
    const contextValue = React.useMemo(() => (Object.assign(Object.assign({}, context), { firstLevel: false })), [context]);
    return (<MenuContext.Provider value={contextValue}>
      <RcSubMenu {...omit(props, ['icon'])} title={titleNode} popupClassName={classNames(prefixCls, `${prefixCls}-${antdMenuTheme}`, popupClassName)}/>
    </MenuContext.Provider>);
}
export default SubMenu;
