var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcCheckbox from 'rc-checkbox';
import classNames from 'classnames';
import { composeRef } from 'rc-util/lib/ref';
import { ConfigContext } from '../config-provider';
import RadioGroupContext from './context';
import devWarning from '../_util/devWarning';
const InternalRadio = (props, ref) => {
    const context = React.useContext(RadioGroupContext);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const innerRef = React.useRef();
    const mergedRef = composeRef(ref, innerRef);
    React.useEffect(() => {
        if (process.env.NODE_ENV !== 'production')
            devWarning(!('optionType' in props), 'Radio', '`optionType` is only support in Radio.Group.');
    }, []);
    const onChange = (e) => {
        var _a, _b;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, e);
        (_b = context === null || context === void 0 ? void 0 : context.onChange) === null || _b === void 0 ? void 0 : _b.call(context, e);
    };
    const { prefixCls: customizePrefixCls, className, children, style } = props, restProps = __rest(props, ["prefixCls", "className", "children", "style"]);
    const prefixCls = getPrefixCls('radio', customizePrefixCls);
    const radioProps = Object.assign({}, restProps);
    if (context) {
        radioProps.name = context.name;
        radioProps.onChange = onChange;
        radioProps.checked = props.value === context.value;
        radioProps.disabled = props.disabled || context.disabled;
    }
    const wrapperClassString = classNames(`${prefixCls}-wrapper`, {
        [`${prefixCls}-wrapper-checked`]: radioProps.checked,
        [`${prefixCls}-wrapper-disabled`]: radioProps.disabled,
        [`${prefixCls}-wrapper-rtl`]: direction === 'rtl',
    }, className);
    return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={wrapperClassString} style={style} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      <RcCheckbox {...radioProps} type="radio" prefixCls={prefixCls} ref={mergedRef}/>
      {children !== undefined ? <span>{children}</span> : null}
    </label>);
};
const Radio = React.forwardRef(InternalRadio);
Radio.displayName = 'Radio';
export default Radio;
